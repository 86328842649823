li {
  line-height: 1.3;
}

h2 {
  text-decoration: underline;
}

.App {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 78px;
}

.App-content {
  text-align: left;
  padding-top: 48px;
  padding-bottom: 48px;
}

.App-logo {
  height: 40vmin;
  margin: 40px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.EmailSignup {
  margin: 0 auto;
  max-width: 400px;
}

.EmailSignupForm {
  text-align: left;
}

.FormLabel {
  text-align: left;
}

.FormControl {
  margin-bottom: 8px;
}

.insta-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  width: 30px;
}
