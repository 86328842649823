.Contact {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

.Contact-text {
  font-size: small;
}

Form {
  border-radius: 10px;
  padding: 20px;
}
